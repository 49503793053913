import axios from "axios";
import {
  API_REQUEST_TIMED_OUT,
  API_URL,
  ASSIGN_BASE,
  EFFECTEDSTUDY,
  POLICY_LIST_FETCH,
  ROLES_LIST,
  STUDYMDMEXITS,
  STUDYSEARCH,
  STUDYSEARCHEXITS,
  STUDYSPONSOREXITS,
  SYSTEMSEARCHEXITS,
  SYSTEMSEARCHEXITSSTUDY,
  SYSTEM_ASSIGNMENT_BASE,
  TENANTSEARCHEXISTS,
  USER_DELETE,
  VENDOR_BASE,
  baseURL,
} from "../constants";
import {
  accessTokenCookieGenerator,
  apiKeyCookieGenerator,
  deleteAllCookies,
  getCookie,
  getTenantId,
  getUserId,
} from "../utils";

const userId = getUserId();
const CT = axios.CancelToken;
const token = getCookie("user.token");
const tenantName = getTenantId();
const searchData = {
  system: SYSTEMSEARCHEXITS,
  system_entity_study: SYSTEMSEARCHEXITSSTUDY,
  studySearch: STUDYSEARCHEXITS,
  study: STUDYSEARCH,
  mdm: STUDYMDMEXITS,
  sponsor: STUDYSPONSOREXITS,
  tenantSearch: TENANTSEARCHEXISTS,
};
const timeOut = process.env.REACT_APP_API_TIMEOUT || 8000;

// const setCookiesClient = setCookiesOnWeb()

axios.defaults.headers.common["api-key"] = apiKeyCookieGenerator();
axios.defaults.headers.common["sys-name"] =
  process.env.REACT_APP_SYS_NAME || "";
axios.defaults.headers.common["token-type"] = "user";
axios.defaults.headers.common["access-token"] = accessTokenCookieGenerator();
axios.defaults.headers.common.Authorization = `Bearer ${token}`;
axios.defaults.headers.common.tenant = tenantName;

const ListURL = {
  study: ASSIGN_BASE,
  system: SYSTEM_ASSIGNMENT_BASE,
  users: USER_DELETE,
};

const SOMETHING_WENT_WRONG = "Something went wrong";

export const serverSideWebPage = async () => {
  try {
    const res = await axios.post(`${baseURL}/v1/api/webpage/server`, {
      fileName: "app-switcher",
      userId: getUserId(),
      productName: "Admin",
    });
    return res?.data;
  } catch (err) {
    return console.log("Error", err);
  }
};

export const profileData = async (req) => {
  try {
    const res = await axios.post(
      `${baseURL}/v1/api/webpage/server/profile`,
      req
    );
    return res?.data;
  } catch (err) {
    return console.log("Error", err);
  }
};

export const searchDataExists = async (searchType, searchQuery = "") => {
  try {
    const searchUrl =
      searchType === "sponsor"
        ? `${searchData[searchType]}${encodeURIComponent(searchQuery)}`
        : `${searchData[searchType]}/${searchQuery}`;
    const url = searchQuery
      ? `${baseURL}/${searchUrl}`
      : `${baseURL}/${STUDYSEARCH}`;
    const res = await axios.get(url);
    return res?.data?.data;
  } catch (err) {
    return console.log("Error", err);
  }
};

export const tenantNameExists = async (searchType, searchQuery = "") => {
  try {
    const searchUrl = `${searchData[searchType]}${searchQuery}`;
    const url = `${baseURL}/${searchUrl}`;
    const res = await axios.get(url);
    return res?.data?.data;
  } catch (err) {
    return console.log("Error", err);
  }
};

export const searchNameExits = async (searchType, searchQuery = "") => {
  const globalUrl = `${baseURL}/${searchData[searchType]}/${encodeURIComponent(
    searchQuery
  )}`;
  try {
    const url = searchQuery ? globalUrl : `${baseURL}/${STUDYSEARCH}`;
    const axiosType = axios.get(url);
    const res = await axiosType;
    return res?.data?.message;
  } catch (err) {
    return console.log("Error", err);
  }
};

export const searchNameExitsSystemStudy = async (
  searchType,
  searchQuery = ""
) => {
  const globalUrl = `${baseURL}/${searchData[searchType]}/${searchQuery}`;
  try {
    let message;
    if (searchQuery?.length > 0) {
      const axiosType = axios.get(globalUrl);
      const res = await axiosType;
      message = res?.data?.message;
    } else {
      message = false;
    }
    return message;
  } catch (err) {
    return console.log("Error", err);
  }
};

export const effectedStudieslist = async (searchQuery = "") => {
  try {
    const url = searchQuery
      ? `${baseURL}/${EFFECTEDSTUDY}/${searchQuery}`
      : `${baseURL}/${EFFECTEDSTUDY}`;
    const res = await axios.get(url);
    console.log(res.data);
    return res?.data?.data;
  } catch (err) {
    return console.log("Error", err);
  }
};

export const addPolicyService = async (reqBody) => {
  try {
    return new Promise((resolve, reject) => {
      axios
        .post(`${API_URL}/policy/create`, reqBody)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err.response.data);
        });
    });
  } catch (err) {
    return console.log("Error", err);
  }
};

export const addRoleService = async (reqBody) => {
  try {
    return new Promise((resolve, reject) => {
      axios
        .post(`${API_URL}/role/create`, reqBody)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err.response.data);
        });
    });
  } catch (err) {
    return console.log("Error", err);
  }
};

export const updateRoleService = async (reqBody) => {
  try {
    return new Promise((resolve, reject) => {
      axios
        .post(`${API_URL}/role/update`, reqBody)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err.response.data);
        });
    });
  } catch (err) {
    return console.log("Error", err);
  }
};

export const updatePolicyService = async (reqBody) => {
  try {
    return new Promise((resolve, reject) => {
      axios
        .post(`${API_URL}/policy/update`, reqBody)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err.response.data);
        });
    });
  } catch (err) {
    return console.log("Error", err);
  }
};

export const addVendorService = async (reqBody) => {
  try {
    return new Promise((resolve, reject) => {
      axios
        .post(`${baseURL}/${VENDOR_BASE}/create`, reqBody)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err.response.data);
        });
    });
  } catch (err) {
    return console.log("Error", err);
  }
};

export const deleteVendorContact = async (reqBody) => {
  try {
    return new Promise((resolve, reject) => {
      axios
        .post(`${baseURL}/${VENDOR_BASE}/contact/delete`, reqBody)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err.response.data);
        });
    });
  } catch (err) {
    return console.log("Error", err);
  }
};

export const statusUpdate = async (vId, vStatus) => {
  try {
    return new Promise((resolve) => {
      axios
        .post(`${baseURL}/${VENDOR_BASE}/statusUpdate`, {
          vId,
          vStatus,
          userId,
          updt_tm: new Date().toISOString(),
        })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          console.log("Err", err);
          if (err.response) {
            resolve(err.response.data);
          }
        });
    });
  } catch (err) {
    return console.log("Error", err);
  }
};

export const getPolicyPermissions = async (policyId = "") => {
  try {
    return new Promise((resolve) => {
      axios
        .get(`${API_URL}/policy/permission-list/${policyId}`)
        .then((res) => {
          resolve(res.data.data);
        })
        .catch((err) => {
          console.log("Err", err);
        });
    });
  } catch (err) {
    return console.log("Error", err);
  }
};

export const getPolicySnapshot = async (policyId = "") => {
  try {
    return new Promise((resolve) => {
      axios
        .get(`${API_URL}/policy/snapshot/${policyId}`)
        .then((res) => {
          resolve(res.data.data);
        })
        .catch((err) => {
          console.log("Err", err);
        });
    });
  } catch (err) {
    return console.log("Error", err);
  }
};

export const getRolePolicySnapshot = async (policyId = "") => {
  try {
    return new Promise((resolve) => {
      axios
        .get(`${API_URL}/policy/role/snapshot/${policyId}`)
        .then((res) => {
          resolve(res.data.data);
        })
        .catch((err) => {
          console.log("Err", err);
        });
    });
  } catch (err) {
    return console.log("Error", err);
  }
};

export const fetchRoles = async () => {
  try {
    return new Promise((resolve) => {
      axios
        .get(`${API_URL}/${ROLES_LIST}`)
        .then((res) => {
          resolve(res.data.data);
        })
        .catch((err) => {
          console.log("Err", err);
        });
    });
  } catch (err) {
    return console.log("Error", err);
  }
};
export const getRolePolicies = async (roleId) => {
  try {
    return new Promise((resolve) => {
      axios
        .get(`${baseURL}/${POLICY_LIST_FETCH}/${roleId}`)
        .then((res) => {
          resolve(res.data.data);
        })
        .catch((err) => {
          console.log("Err", err);
        });
    });
  } catch (err) {
    return console.log("Error", err);
  }
};

export const getOnboardUsers = async () => {
  try {
    return new Promise((resolve) => {
      axios
        .get(`${API_URL}/users/list`)
        .then((res) => {
          let data = res.data?.data?.rows || [];
          data = data?.map((user) => {
            if (user?.act_flg === 1 || user?.act_flg === 2) {
              return {
                userId: user?.usr_id?.trim(),
                firstName: user?.usr_fst_nm?.trim(),
                lastName: user?.usr_lst_nm?.trim(),
                email: user?.usr_mail_id?.trim(),
                act_flg: user?.act_flg,
                label: `${user?.usr_fst_nm?.trim()} ${user?.usr_lst_nm?.trim()} (${user?.usr_mail_id?.trim()})`,
              };
            }
            return null;
          });
          data = data?.filter((e) => {
            return e != null;
          });
          resolve(data || []);
        })
        .catch((err) => {
          console.log("Error", err);
          resolve(null);
        });
    });
  } catch (err) {
    return console.log("Error", err);
  }
};

export const getRoleDetails = async (roleId) => {
  try {
    return new Promise((resolve) => {
      axios
        .get(`${API_URL}/role/${roleId}`)
        .then((res) => {
          resolve(res.data.data);
        })
        .catch((err) => {
          console.log(err);
          resolve(null);
        });
    });
  } catch (err) {
    return console.log("Error", err);
  }
};

export const fetchProducts = async () => {
  try {
    return new Promise((resolve) => {
      axios
        .get(`${API_URL}/policy/products`)
        .then((res) => {
          resolve(res.data.data);
        })
        .catch((err) => {
          console.log("Err", err);
        });
    });
  } catch (err) {
    return console.log("Error", err);
  }
};

export const onboardStudy = (reqBody) => {
  try {
    return new Promise((resolve) => {
      axios
        .post(`${API_URL}/study/onboard`, reqBody)
        .then((res) => {
          resolve(res.data?.data || res.data);
        })
        .catch((err) => {
          if (err.response?.data) {
            resolve(err.response?.data);
          } else {
            resolve({ status: "BAD_REQUEST", message: SOMETHING_WENT_WRONG });
          }
        });
    });
  } catch (err) {
    return console.log("Error", err);
  }
};

// eslint-disable-next-line import/prefer-default-export
export const updateSystem = (reqBody) => {
  try {
    return new Promise((resolve) => {
      axios
        .put(`${API_URL}/system/update`, reqBody)
        .then((res) => {
          resolve(res.data?.data || res.data);
        })
        .catch((err) => {
          if (err.response?.data) {
            resolve(err.response?.data);
          } else {
            resolve({ status: "BAD_REQUEST", message: SOMETHING_WENT_WRONG });
          }
        });
    });
  } catch (err) {
    return console.log("Error", err);
  }
};

// eslint-disable-next-line import/prefer-default-export
export const saveStudyDataManual = (params) => {
  try {
    return new Promise((resolve) => {
      axios
        .post(`${API_URL}/study/createstudy`, params)
        .then((res) => {
          resolve(res.data?.data || res.data);
        })
        .catch((err) => {
          if (err.response?.data) {
            resolve(err.response?.data);
          } else {
            resolve({ status: "BAD_REQUEST", message: SOMETHING_WENT_WRONG });
          }
        });
    });
  } catch (err) {
    return console.log("Error", err);
  }
};

export const saveSystemDataManual = (params) => {
  try {
    return new Promise((resolve) => {
      axios
        .post(`${API_URL}/system/create`, params)
        .then((res) => {
          resolve(res.data?.data || res.data);
        })
        .catch((err) => {
          if (err.response?.data) {
            resolve(err.response?.data);
          } else {
            resolve({ status: "BAD_REQUEST", message: SOMETHING_WENT_WRONG });
          }
        });
    });
  } catch (err) {
    return console.log("Error", err);
  }
};

// eslint-disable-next-line import/prefer-default-export
export const updateStudyDataManual = (params) => {
  try {
    return new Promise((resolve) => {
      axios
        .put(`${API_URL}/study/updateStudyDetails`, params)
        .then((res) => {
          resolve(res.data?.data || res.data);
        })
        .catch((err) => {
          if (err.response?.data) {
            resolve(err.response?.data);
          } else {
            resolve({ status: "BAD_REQUEST", message: SOMETHING_WENT_WRONG });
          }
        });
    });
  } catch (err) {
    return console.log("Error", err);
  }
};

export const getRolesPermissions = () => {
  try {
    return new Promise((resolve) => {
      axios
        .post(`${API_URL}/role/getUserRolesPermissions`, {
          userId,
          productName: "Admin",
        })
        .then((res) => {
          resolve(res?.data?.data || res.data);
        })
        .catch((err) => {
          if (err.response) {
            resolve(err.response);
          } else {
            resolve({ message: SOMETHING_WENT_WRONG });
          }
        });
    });
  } catch (err) {
    return console.log("Error", err);
  }
};

export const getUserRoles = async (id) => {
  try {
    return new Promise((resolve) => {
      axios
        .get(`${API_URL}/users/get-user-roles`, {
          params: {
            userId: id,
          },
        })
        .then((res) => {
          resolve(res?.data?.roles);
        })
        .catch((err) => {
          if (err.response) {
            resolve(err.response);
          } else {
            resolve({ message: SOMETHING_WENT_WRONG });
          }
        });
    });
  } catch (err) {
    return console.log("Error", err);
  }
};

export const getTenants = () => {
  try {
    return new Promise((resolve) => {
      axios
        .post(`${API_URL}/common/getTenants`, { userId })
        .then((res) => {
          resolve(res?.data?.data || res.data);
        })
        .catch((err) => {
          if (err.response) {
            resolve(err.response);
          } else {
            resolve({ message: SOMETHING_WENT_WRONG });
          }
        });
    });
  } catch (err) {
    return console.log("Error", err);
  }
};
export const submitTenantsID = () => {
  try {
    return new Promise((resolve) => {
      axios
        .put(`${API_URL}/users/update-login-details-tenant`, { userId })
        .then((res) => {
          resolve(res?.data?.data || res.data);
        })
        .catch((err) => {
          if (err.response) {
            resolve(err.response);
          } else {
            resolve({ message: SOMETHING_WENT_WRONG });
          }
        });
    });
  } catch (err) {
    return console.log("Error", err);
  }
};

export const userLogOut = async () => {
  return axios
    .get(`${baseURL}/logout`)
    .then(async (res) => {
      if (res.data) {
        return deleteAllCookies();
      }
      return false;
    })
    .catch((err) => {
      console.log(err);
    });
};

export const getAssignedUsers = async (protId, page) => {
  try {
    return new Promise((resolve, reject) => {
      axios
        .post(`${baseURL}/${ListURL[page]}/list`, { protocol: protId })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err?.response?.data);
        });
    });
  } catch (err) {
    return console.log("Error", err);
  }
};

export const addAssignUser = async (reqBody, page) => {
  try {
    return new Promise((resolve) => {
      axios
        .post(`${baseURL}/${ListURL[page]}/add`, reqBody)
        .then((res) => {
          resolve(res?.data);
        })
        .catch((err) => {
          resolve(err?.response?.data);
        });
    });
  } catch (err) {
    return console.log("Error", err);
  }
};

export const updateAssignUser = async (reqBody, page) => {
  try {
    return new Promise((resolve, reject) => {
      axios
        .post(`${baseURL}/${ListURL[page]}/update`, reqBody)
        .then((res) => {
          resolve(res?.data);
        })
        .catch((err) => {
          reject(err?.response?.data);
        });
    });
  } catch (err) {
    return console.log("Error", err);
  }
};

export const deleteAssignUser = async (reqBody, page) => {
  try {
    return new Promise((resolve) => {
      axios
        .post(`${baseURL}/${ListURL[page]}/delete`, reqBody)
        .then((res) => {
          resolve(res?.data);
        })
        .catch((err) => {
          resolve(err?.response?.data);
        });
    });
  } catch (err) {
    return console.log("Error", err);
  }
};

export const deleteManuallyUser = async (reqBody, page) => {
  try {
    return new Promise((resolve, reject) => {
      axios
        .delete(`${baseURL}/${ListURL[page]}/delete`, { data: reqBody })
        .then((res) => {
          resolve(res?.data);
        })
        .catch((err) => {
          reject(err?.response?.data);
        });
    });
  } catch (err) {
    return console.log("Error", err);
  }
};

export const getUsers = () => {
  try {
    return new Promise((resolve) => {
      axios
        .get(`${API_URL}/users/list`)
        .then((res) => {
          resolve(res.data?.data || res.data);
        })
        .catch((err) => {
          if (err.response?.data) {
            resolve(err.response?.data);
          } else {
            resolve({ message: SOMETHING_WENT_WRONG });
          }
        });
    });
  } catch (err) {
    return console.log("Error", err);
  }
};

export const getUser = (id) => {
  try {
    return new Promise((resolve) => {
      axios
        .get(`${API_URL}/user/getUserDetail`, { params: { userId: id } })
        .then((res) => {
          resolve(res.data?.data || res.data);
        })
        .catch((err) => {
          if (err.response?.data) {
            resolve(err.response?.data);
          } else {
            resolve({ message: SOMETHING_WENT_WRONG });
          }
        });
    });
  } catch (err) {
    return console.log("Error", err);
  }
};

export const validateEmail = (email) => {
  try {
    return new Promise((resolve, reject) => {
      axios
        .post(`${API_URL}/users/validate-email`, { email })
        .then((res) => {
          resolve(res?.data);
        })
        .catch((err) => {
          reject(err?.response?.data);
        });
    });
  } catch (err) {
    return console.log("Error", err);
  }
};

export const getStudies = () => {
  try {
    return new Promise((resolve) => {
      axios
        .post(`${API_URL}/study/list`)
        .then((res) => {
          resolve(res?.data?.studyData || res.data);
        })
        .catch((err) => {
          if (err.response?.data) {
            resolve(err.response?.data);
          } else {
            resolve({ message: SOMETHING_WENT_WRONG });
          }
        });
    });
  } catch (err) {
    return console.log("Error", err);
  }
};

export const getRoles = () => {
  try {
    return new Promise((resolve) => {
      axios
        .get(`${API_URL}/role/`)
        .then((res) => {
          resolve(res?.data?.roles || res.data);
        })
        .catch((err) => {
          if (err.response?.data) {
            resolve(err.response?.data);
          } else {
            resolve({ message: SOMETHING_WENT_WRONG });
          }
        });
    });
  } catch (err) {
    return console.log("Error", err);
  }
};

export const inviteExternalUser = (payload) => {
  try {
    return new Promise((resolve) => {
      payload.updatedBy = userId;
      axios
        .post(`${API_URL}/users/invite-external-user`, payload)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          if (err.response?.data) {
            resolve(err.response?.data);
          } else {
            resolve({ message: SOMETHING_WENT_WRONG });
          }
        });
    });
  } catch (err) {
    return console.log("Error", err);
  }
};

export const inviteInternalUser = (payload) => {
  try {
    return new Promise((resolve) => {
      payload.updatedBy = userId;
      axios
        .post(`${API_URL}/users/invite-internal-user`, payload)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          if (err.response?.data) {
            resolve(err.response?.data);
          } else {
            resolve({ message: SOMETHING_WENT_WRONG });
          }
        });
    });
  } catch (err) {
    return console.log("Error", err);
  }
};

export const getUserStudy = (studyUserId) => {
  try {
    return new Promise((resolve) => {
      axios
        .get(`${API_URL}/users/get-user-study`, {
          params: {
            studyUserId,
          },
        })
        .then((res) => {
          resolve(res?.data);
        })
        .catch((err) => {
          if (err.response?.data) {
            resolve(err.response?.data);
          } else {
            resolve({ message: SOMETHING_WENT_WRONG });
          }
        });
    });
  } catch (err) {
    return console.log("Error", err);
  }
};

export const assingUserStudy = async (reqBody) => {
  try {
    reqBody.createdBy = userId;
    return new Promise((resolve, reject) => {
      axios
        .post(`${API_URL}/assignment/create`, reqBody)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err.response?.data);
        });
    });
  } catch (err) {
    return console.log("Error", err);
  }
};

let fetchADUsersCancelToken;
export const fetchADUsers = (query) => {
  if (fetchADUsersCancelToken !== undefined) {
    fetchADUsersCancelToken();
  }
  try {
    return axios
      .post(
        `${API_URL}/users/get-ad-list`,
        {
          query,
        },
        {
          cancelToken: new CT(function executor(c) {
            // An executor function receives a cancel function as a parameter
            fetchADUsersCancelToken = c;
          }),
        }
      )
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        if (axios.isCancel(err)) {
          return { status: -1 };
        }
        if (err.response?.data) {
          return err.response?.data;
        }
        return { message: SOMETHING_WENT_WRONG };
      });
  } catch (err) {
    return console.log("Error", err);
  }
};

export const getUserStudyAndRoles = (userIdForStudyData, typeName) => {
  try {
    return new Promise((resolve) => {
      axios
        .get(`${API_URL}/users/get-user-study-and-roles`, {
          params: {
            userId: userIdForStudyData,
            type: typeName,
          },
        })
        .then((res) => {
          resolve(res?.data);
        })
        .catch((err) => {
          if (err.response?.data) {
            resolve(err.response?.data);
          } else {
            resolve({ message: SOMETHING_WENT_WRONG });
          }
        });
    });
  } catch (err) {
    return console.log("Error", err);
  }
};

/* export const updateUserStatus = (userIdForStatusChange, status) => {
  try {
    return new Promise((resolve, reject) => {
      axios
        .post(`${API_URL}/users/update-user-status`, {
          userId: userIdForStatusChange,
          status,
        })
        .then((res) => {
          resolve(res?.data);
        })
        .catch((err) => {
          if (err.response?.data) {
            resolve({ message: `User status changed to: ${status}` });
          } else {
            resolve({ message: SOMETHING_WENT_WRONG });
          }
        });
    });
  } catch (err) {
    return console.log("Error", err);
  }
}; */

export const updateUserStatus = (payload) => {
  try {
    return new Promise((resolve) => {
      payload.updatedBy = userId;
      axios
        .post(`${API_URL}/users/update-status`, payload)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          if (err.response?.data) {
            resolve(err.response?.data);
          } else {
            resolve({ message: SOMETHING_WENT_WRONG });
          }
        });
    });
  } catch (err) {
    return console.log("Error", err);
  }
};

export const updateUserAssignments = (payload) => {
  try {
    return new Promise((resolve) => {
      payload.updatedBy = userId;
      axios
        .post(`${API_URL}/users/update-user-assignments`, payload)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          if (err.response?.data) {
            resolve(err.response?.data);
          } else {
            resolve({ message: SOMETHING_WENT_WRONG });
          }
        });
    });
  } catch (err) {
    return console.log("Error", err);
  }
};

export const deleteUserAssignments = (payload) => {
  try {
    return new Promise((resolve) => {
      payload.updatedBy = userId;
      axios
        .delete(`${API_URL}/users/delete-user-assignments`, { data: payload })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          if (err.response?.data) {
            resolve(err.response?.data);
          } else {
            resolve({ message: SOMETHING_WENT_WRONG });
          }
        });
    });
  } catch (err) {
    return console.log("Error", err);
  }
};

export const sendInvite = async (payload) => {
  try {
    return new Promise((resolve) => {
      payload.updatedBy = userId;
      axios
        .post(`${API_URL}/users/send-invite`, payload)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          if (err.response?.data) {
            resolve(err.response?.data);
          } else {
            resolve({ message: SOMETHING_WENT_WRONG });
          }
        });
    });
  } catch (err) {
    return console.log("Error", err);
  }
};

export const addTenantService = async (reqBody) => {
  try {
    return new Promise((resolve, reject) => {
      axios
        .post(`${API_URL}/tenant/create`, reqBody)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err.response.data);
        });
    });
  } catch (err) {
    return console.log("Error", err);
  }
};

export const checkUniqueServiceAccountName = async (accountName) => {
  try {
    return new Promise((resolve, reject) => {
      axios
        .get(`${API_URL}/service/check-service-account-name-unique`, {
          params: { accountName },
        })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err.response.data);
        });
    });
  } catch (err) {
    return console.log("Error", err);
  }
};

export const createServiceAccount = async (reqBody) => {
  try {
    return new Promise((resolve, reject) => {
      axios
        .post(`${API_URL}/service/create`, reqBody)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err.response.data);
        });
    });
  } catch (err) {
    return console.log("Error", err);
  }
};

export const addGroupMembers = async (reqBody) => {
  try {
    return new Promise((resolve, reject) => {
      axios
        .post(`${API_URL}/group/add-group-members`, reqBody)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err.response.data);
        });
    });
  } catch (err) {
    return console.log("Error", err);
  }
};

export const updateServiceAccount = async (reqBody) => {
  try {
    return new Promise((resolve, reject) => {
      axios
        .post(`${API_URL}/service/update`, reqBody)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err.response.data);
        });
    });
  } catch (err) {
    return console.log("Error", err);
  }
};

export const checkExternalEmployeeIdUnique = async (employeeId) => {
  try {
    return new Promise((resolve, reject) => {
      axios
        .get(`${API_URL}/user/external-empId-check`, {
          params: { employeeId },
        })
        .then((res) => {
          resolve(res.data.data);
        })
        .catch((err) => {
          reject(err.response.data);
        });
    });
  } catch (err) {
    return console.log("Error", err);
  }
};

export const saveObjectLevelSecurity = async (reqBody) => {
  try {
    return new Promise((resolve, reject) => {
      axios
        .post(`${API_URL}/data-access/save-object-assignment`, reqBody)
        .then((res) => {
          resolve(res?.data);
        })
        .catch((err) => {
          reject(err?.response?.data);
        });
    });
  } catch (err) {
    return console.log("Error", err);
  }
};

export const saveRowLevelSecurity = async (reqBody) => {
  try {
    return new Promise((resolve, reject) => {
      axios
        .post(`${API_URL}/row-level/save-column-assignment`, reqBody)
        .then((res) => {
          resolve(res?.data);
        })
        .catch((err) => {
          reject(err?.response?.data);
        });
    });
  } catch (err) {
    return console.log("Error", err);
  }
};

export const saveRowLevelSuggestions = async (reqBody) => {
  try {
    return new Promise((resolve, reject) => {
      axios
        .post(`${API_URL}/row-level/save-suggested-tables`, reqBody)
        .then((res) => {
          resolve(res?.data);
        })
        .catch((err) => {
          reject(err?.response?.data);
        });
    });
  } catch (err) {
    return console.log("Error", err);
  }
};

export const checkGroupnameExists = async (groupName) => {
  try {
    return new Promise((resolve, reject) => {
      axios
        .post(`${API_URL}/group/check-groupname-exists`, { groupName })
        .then((res) => {
          resolve(res?.data?.message);
        })
        .catch((err) => {
          reject(err?.response?.data);
        });
    });
  } catch (err) {
    return console.log("Error", err);
  }
};

export const createGroup = async (reqBody) => {
  try {
    return new Promise((resolve, reject) => {
      axios
        .post(`${API_URL}/group/create-group`, reqBody)
        .then((res) => {
          resolve(res?.data);
        })
        .catch((err) => {
          reject(err?.response?.data);
        });
    });
  } catch (err) {
    return console.log("Error", err);
  }
};

export const removeGroupMember = async (reqBody) => {
  try {
    return new Promise((resolve, reject) => {
      axios
        .post(`${API_URL}/group/delete-group-member`, reqBody)
        .then((res) => {
          resolve(res?.data);
        })
        .catch((err) => {
          reject(err?.response?.data);
        });
    });
  } catch (err) {
    return console.log("Error", err);
  }
};

export const updateGroupDetails = async (reqBody) => {
  try {
    return new Promise((resolve, reject) => {
      axios
        .post(`${API_URL}/group/edit-group-details`, reqBody)
        .then((res) => {
          resolve(res?.data);
        })
        .catch((err) => {
          reject(err?.response?.data);
        });
    });
  } catch (err) {
    return console.log("Error", err);
  }
};

export const fetchUserAccessDataWithTimeOut = async (reqBody) => {
  return new Promise((resolve, reject) => {
    const error = new Error("Timeout Error");
    error.message = API_REQUEST_TIMED_OUT;
    const timer = setTimeout(() => reject(error), timeOut);
    axios
      .post(`${API_URL}/users/access-reports`, reqBody)
      .then((res) => {
        clearTimeout(timer);
        resolve(res?.data);
      })
      .catch((err) => {
        clearTimeout(timer);
        reject(err?.response);
      });
  });
};

export const fetchUserAccessData = async (reqBody) => {
  return new Promise((resolve, reject) => {
    axios
      .post(`${API_URL}/users/access-reports`, reqBody)
      .then((res) => {
        resolve(res?.data);
      })
      .catch((err) => {
        reject(err?.response);
      });
  });
};
